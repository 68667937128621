import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';

// Component styles.
import styles from './menu-panel.module.scss';

const MenuPanel = ({ menuItems }) => (
  <div className={styles.panel}>
    <ul className={styles.dropdownPanel}>
      {menuItems.map((item, index) => (
        <li className={styles.dropdownPanelItem} key={index}>
          <Link to={item.link} className={styles.panelTitle}>
            {item.title === 'line break' ? (
              <div className={styles.linebreak} />
            ) : (
              item.title
            )}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

MenuPanel.propTypes = {
  menuItems: PropTypes.array.isRequired
};

export default MenuPanel;
