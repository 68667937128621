import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

/**
 * A Link component that can accept an external url or internal path.
 *
 * Will return a Gatsby <Link> if the path is internal or a regular
 * <a href=""> if the path is external.
 *
 * @param {JSX} children The children passed into the component.
 * @param {string} className The class names passed into the component.
 * @param {bool} disabled Whether or not the link is disabled.
 * @param {func} onClick A click handler function.
 * @param {string} to A path or url.
 * @return {JSX} A <Link> or <a href="">
 */
const GatsbyLink = ({
  children,
  className,
  disabled,
  onClick,
  to,
  ...props
}) => {
  // The mega-menu uses the Drupal menu `Inside Sandy Springs`.
  // In Drupal you can't add a link to `/some/page` without it actually
  // existing in Drupal. To get around that we're adding the full URL to Drupal.
  // i.e. https://inside.sandyspringsga.gov/some/page.
  //
  // This introduces a new problem in that Gatsby-Link expects a relative link
  // (i.e. /some/page) for client side routing to work correctly.
  //
  // So we need to convert absolute links _back_ to relative links but only for
  // Inside Sandy Springs domains.
  const InsideSandyDomains = [
    'https://inside.sandyspringsga.gov',
    'https://develop-unified-platform.pantheonsite.io',
    'https://live-unified-platform.pantheonsite.io',
    'https://insidesandy-unified-platform.pantheonsite.io',
    'http://localhost:8000'
  ];

  let href = to;
  // Loop through all of the domains and if a match is found,
  // strip out the domain making it a relative URL.
  for (let i = 0; i < InsideSandyDomains.length; i++) {
    // No IE11 support so we can use str.startsWith().
    // See if the `to` url starts with one of the Inside Sandy Springs domains.
    if (
      to.startsWith(InsideSandyDomains[i]) &&
      !to.endsWith('pdf') &&
      !to.endsWith('txt') &&
      !to.endsWith('doc') &&
      !to.endsWith('docx')
    ) {
      // Strip out the domain so it's a relative path.
      // (i.e. https://inside.sandyspringsga.gov/some/page -> /some/page)
      href = href.replace(InsideSandyDomains[i], '');
      break;
    }
  }

  // If the `href` prop has no protocol,
  // return a Gatsby <GatsbyLink> component.
  if (
    href &&
    href.indexOf('http') === -1 &&
    href.indexOf('tel:') === -1 &&
    href.startsWith('#') === false
  ) {
    return (
      <Link
        onClick={onClick}
        disabled={disabled || false}
        className={className}
        to={href}
        {...props}
      >
        {children}
      </Link>
    );
  }
  // If the `to` property exists and has a protocol,
  // return an HTML <a href="">.
  else if (
    href &&
    (href.indexOf('http') !== -1 ||
      href.indexOf('tel:') !== -1 ||
      href.startsWith('#'))
  ) {
    return (
      <a
        disabled={disabled || false}
        className={className}
        href={href}
        onClick={onClick}
        {...props}
      >
        {children}
      </a>
    );
  }

  return null;
};

GatsbyLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired
};

export default GatsbyLink;
