import React from 'react';
import PropTypes from 'prop-types';

// Available Icons.
import { ReactComponent as Close } from './svg/close.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Loader } from './svg/loader.svg';

const Icon = (props) => {
  switch (props.icon) {
    case 'close':
      return <Close {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'loader':
      return <Loader {...props} fill="#fff" />;
    default:
      return null;
  }
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired
};

export default Icon;
