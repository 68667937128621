import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from '../link';
import styles from './button.module.scss';

/**
 * The button component will return a single HTML <button>, <a>,
 * or a <Link> component.
 *
 * @return {JSX} A button or link.
 */
const Button = ({
  borderStyle = 'round',
  buttonContent,
  children,
  className,
  color = 'blue',
  disabled,
  onClick,
  onFocus,
  size,
  textStyle,
  to,
  href,
  ...props
}) => {
  // Concatenates the appropriate classes.
  const classes = classnames(styles.button, {
    [styles[borderStyle]]: borderStyle,
    [styles[color]]: !disabled,
    [styles[disabled]]: disabled,
    [styles.small]: size === 'small',
    [styles.large]: size === 'large',
    [styles[textStyle]]: textStyle,
    [className]: true
  });

  // If the `to` prop exists, return a link.
  if (to) {
    return (
      <Link
        aria-label={props['aria-label']}
        onClick={onClick}
        disabled={disabled || false}
        className={classes}
        to={to}
      >
        {children || buttonContent}
      </Link>
    );
  }

  // If `href` prop is used instead of `to` we use a
  // traditional <a> element with the target="blank" and
  // rel="noopener noreferrer", not a Gatsby <Link> or
  // <button> element. According to Gatsby docs, routing
  // to target="_blank" is the equivalent of an external
  // page and Gatsby <Link> cannot be used. Docs here:
  // https://www.gatsbyjs.com/docs/linking-between-pages/#using-a-for-external-links
  if (href) {
    return (
      <a
        aria-label={props['aria-label']}
        onClick={onClick}
        disabled={disabled || false}
        className={classes}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children || buttonContent}
      </a>
    );
  }

  // If the `to` or `href` props do not exist, return an HTML <button>.
  return (
    <button
      aria-expanded={props['aria-expanded'] || null}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled || false}
      className={classes}
      {...props}
    >
      {children || buttonContent}
    </button>
  );
};

Button.propTypes = {
  borderStyle: PropTypes.oneOf(['round', 'square']),
  buttonContent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'blueMedium',
    'white',
    'blueLight',
    'blue',
    'blueDark',
    'transparent',
    false
  ]),
  disabled: PropTypes.oneOf(['disabled']),
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  size: PropTypes.oneOf(['large', 'small']),
  textStyle: PropTypes.oneOf(['upper']),
  to: PropTypes.string,
  href: PropTypes.string
};

export default Button;
