import { useStaticQuery, graphql } from 'gatsby';

export const useMenuData = () => {
  const { allMenuLinkContentMenuLinkContent } = useStaticQuery(
    graphql`
      query MenuItems {
        allMenuLinkContentMenuLinkContent(
          filter: {
            menu_name: { eq: "inside-sandy-springs" }
            enabled: { eq: true }
          }
          sort: { fields: weight }
        ) {
          nodes {
            title
            drupal_id
            link {
              uri
            }
            drupal_parent_menu_item
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const topLevelItems = allMenuLinkContentMenuLinkContent?.nodes.filter(
    (item) => item.drupal_parent_menu_item === null
  );

  const menuTree = topLevelItems.map((item) => {
    // For each top level item, find it's children.
    const childItems = allMenuLinkContentMenuLinkContent?.nodes
      .filter(
        (child) =>
          `menu_link_content:${item.drupal_id}` ===
          child.drupal_parent_menu_item
      )
      .map((child) => {
        return {
          title: child.title,
          // If this isn't a absolute url it's not something we can work with so
          // swap it out for a plain '#'.
          // No IE11 support for `.startsWith()` but that's no big deal for this project.
          link: child.link.uri.startsWith('http') ? child.link.uri : '#',
          id: child.drupal_id
        };
      });

    return {
      title: item.title,
      link: item.link.uri.startsWith('http') ? item.link.uri : '#',
      id: item.drupal_id,
      submenu: childItems
    };
  });

  return menuTree;
};
