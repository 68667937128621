import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Custom components.
import Button from '../button';

// Styles.
import styles from './mega-menu.module.scss';

/**
 * The Dropdown component provides the interactive
 * functionality to show / hide some content triggered via click.
 *
 * Returns a <Button> and, if the button is clicked, the children
 * that were passed in.
 */
const Dropdown = ({ buttonContent, children, active, handleClick }) => {
  const buttonClasses = classnames([styles.button], {
    [styles.buttonActive]: active
  });
  return (
    <>
      <Button
        onClick={handleClick}
        aria-expanded={active}
        className={buttonClasses}
        borderStyle="square"
        color="white"
        type="button"
      >
        {buttonContent}
      </Button>
      {active ? children : null}
    </>
  );
};

Dropdown.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  handleClick: PropTypes.func
};

export default Dropdown;
