import React, { useState, useEffect } from 'react';
import Logo from '../logo';
import Search from '../search';
import styles from './header.module.scss';
import MegaMenu from '../mega-menu/mega-menu';
import HamburgerMenu from '../hamburger-menu';

const Header = () => {
  const [isMega, setMega] = useState(false);
  const handleResize = () => {
    if (window.innerWidth > 1540) {
      setMega(true);
    } else {
      setMega(false);
    }
  };

  // Displays the MegaMenu vs. the HamburgerMenu depending on viewport width.
  useEffect(() => {
    setMega(window.innerWidth > 1540);
    window.addEventListener('resize', handleResize);

    // Cleanup the function after the component is unmounted.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div>
          <Logo />
        </div>
        <div className={styles.headerRight}>
          {isMega ? (
            <>
              <MegaMenu />
              <Search />
            </>
          ) : (
            <>
              <Search />
              <HamburgerMenu />
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
