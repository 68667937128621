import React from 'react';

import Link from '../link';

import { ReactComponent as SiteLogo } from './assets/inside-sandy-springs-logo.svg';

import styles from './logo.module.scss';

const Logo = (props) => (
  <Link {...props} aria-label="Go to Inside Sandy Springs home page" to="/">
    <SiteLogo className={styles.siteLogo} />
  </Link>
);

export default Logo;
