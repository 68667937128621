import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { useMenuData } from '../../hooks/use-menu-data';

import Button from '../button';
import Dropdown from './mega-menu-dropdown';
import MenuPanel from '../menu-panel';

import styles from './mega-menu.module.scss';

const MegaMenu = () => {
  const [openMenu, setOpen] = useState(null);
  // Query the menu data.
  const data = useMenuData();
  return (
    <div className={styles.header}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(null);
        }}
      >
        <ul className={styles.menuWrapper}>
          {data.map((item) => (
            <li key={`menu-${item.id}`} className={styles.menuItem}>
              {/* If there are submenu items show the dropdown. */}
              {item.submenu.length > 0 && (
                <Dropdown
                  handleClick={() => {
                    const menuState =
                      openMenu === item.title ? null : item.title;
                    setOpen(menuState);
                  }}
                  active={openMenu === item.title && true}
                  buttonContent={item.title}
                >
                  <MenuPanel menuItems={item.submenu} />
                </Dropdown>
              )}
              {/* If not show a single button. */}
              {item.submenu.length === 0 && (
                <Button
                  className={styles.button}
                  borderStyle="square"
                  color="white"
                  to={item.link}
                >
                  {item.title}
                </Button>
              )}
            </li>
          ))}
        </ul>
      </OutsideClickHandler>
    </div>
  );
};

export default MegaMenu;
