import React, { useState } from 'react';

// Custom components.
import Button from '../button/button';
import HamburgerAccordion from './hamburger-accordion';

// Data.
import { useMenuData } from '../../hooks/use-menu-data';

// Styles.
import styles from './hamburger-menu.module.scss';

// Icons.
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Hamburger } from './assets/hamburger.svg';

const HamburgerMenu = () => {
  const [openMenu, setOpen] = useState(false);
  const [activeItem, setActive] = useState(null);

  // Query the menu data.
  const navItems = useMenuData();

  return (
    <>
      {openMenu === false ? (
        <Button
          aria-expanded="false"
          aria-label="Open the primary navigation"
          color={false}
          className={styles.menuToggle}
          onClick={() => setOpen(true)}
          borderStyle="square"
        >
          <Hamburger fill="#fff" height="26" width="33" />
        </Button>
      ) : (
        <>
          <Button
            aria-expanded="false"
            aria-label="Open the primary navigation"
            color={false}
            className={styles.menuToggle}
            onClick={() => setOpen(false)}
            borderStyle="square"
          >
            <Close fill="#fff" height="26" width="26" />
          </Button>
          <nav className={styles.mobileNav}>
            <ul className={styles.mobileNavList}>
              {navItems.map((item) => (
                <li key={`hamburger-menu-${item.id}`}>
                  {/* If there are submenu items show the dropdown. */}
                  {item.submenu.length > 0 && (
                    <HamburgerAccordion
                      handleClick={() => {
                        const menuState =
                          activeItem === item.title ? null : item.title;
                        setActive(menuState);
                      }}
                      active={activeItem === item.title || null}
                      className={styles.mobileDropdownButton}
                      color="blueDark"
                      links={item.submenu}
                      buttonContent={item.title}
                      borderStyle="square"
                    />
                  )}
                  {/* If not show a single button. */}
                  {item.submenu.length === 0 && (
                    <Button
                      className={styles.mobileDropdownButton}
                      borderStyle="square"
                      color="blueDark"
                      to={item.link}
                    >
                      {item.title}
                    </Button>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default HamburgerMenu;
