// Object.assign polyfill for React Spring needed for IE11 support.
import 'core-js/fn/object/assign';
import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button/button';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import Link from '../link';
import styles from './hamburger-menu.module.scss';

/**
 * This component is like the generic Dropdown component, but includes the actual
 * menu items so we can set aria-hidden / tabindex based on the state of this menu.
 */
const HamburgerAccordion = ({ links, active, handleClick, buttonContent }) => {
  return (
    <>
      <Button
        onClick={handleClick}
        aria-expanded={active}
        className={styles.mobileDropdownButton}
        borderStyle="square"
        color="blueDark"
      >
        {buttonContent}
        <Arrow className={styles.arrow} height="9" width="9" />
      </Button>
      {active && (
        <ul>
          {links &&
            links.map((item, index) => (
              <>
                {item.title !== 'line break' ? (
                  <li className={styles.subMenuItem} key={index}>
                    <Link
                      tabIndex={active ? 0 : -1}
                      onClick={handleClick}
                      className={styles.subMenuLink}
                      to={item.link}
                    >
                      <span>{item.title}</span>
                    </Link>
                  </li>
                ) : (
                  <div className={styles.mobileLineBreak} />
                )}
              </>
            ))}
        </ul>
      )}
    </>
  );
};

HamburgerAccordion.propTypes = {
  animate: PropTypes.bool,
  buttonContent: PropTypes.node.isRequired,
  links: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  active: PropTypes.bool
};

export default HamburgerAccordion;
