/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../header';
import Icon from '../icon';

// Import global styles used on every page.
import '../../global/fonts/fonts.scss';
import '../../global/global.scss';

import styles from './layout.module.scss';

const Layout = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const storageKey = 'Dx9BfmBb-ZBKrQmjkn';
    // Check here to see if the cookie exists.
    if (!localStorage.getItem(storageKey)) {
      // No cookie set, check access.
      fetch('/.netlify/functions/access')
        .then((response) => response.json())
        .then((data) => {
          if (data.authorized) {
            setAuthorized(true);
            localStorage.setItem(storageKey, true);
          } else {
            // Redirect to htaccess page /auth.
            // We have to do this outside of Gatsby to simulate
            // a full page load.
            window.location.assign(`${window.location.origin}/auth`);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          // Redirect to /auth.
          window.location.assign(`${window.location.origin}/auth`);
        });
    } else {
      setAuthorized(true);
    }
  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (authorized === false) {
    return (
      <Icon icon="loader" height="150" width="150" className={styles.loader} />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.layoutContainer}>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
